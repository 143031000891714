import React from 'react';
import { Link } from 'react-router-dom';
import './PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
  return (<div className='content-container'>
    <div className='privacy-container'>
    <h1>PRIVACY POLICY</h1>
    <p><b>&nbsp;</b></p>
    <p><b>Last updated March 24, 2024</b></p>
    <p>&nbsp;</p>
    <p>This privacy notice for&nbsp;Alert Frontier LLC&nbsp;(
&quot;<b>we</b>,&quot; &quot;<b>us</b>,&quot; or &quot;<b>our</b>&quot; ),
describes how and why we might collect, store, use, and/or share (&quot;<b>process</b>&quot;)
your information when you use our services (&quot;<b>Services</b>&quot;), such
as when you:</p>

<ul>
 <li>Visit our website at&nbsp;<a
     href="http://www.alertfrontier.com/" target="_blank">http://www.alertfrontier.com</a>,
     or any website of ours that links to this privacy notice</li>
</ul>

<ul>
 <li>Download and use our mobile application (Alert Responder),
     or any other application of ours that links to this privacy notice</li>
</ul>

<ul>
 <li>Engage with us in other related ways, including any sales,
     marketing, or events</li>
</ul>

<p><b>Questions or concerns?&nbsp;</b>Reading this privacy
notice will help you understand your privacy rights and choices. If you do not
agree with our policies and practices, please do not use our Services. If you
still have any questions or concerns, please contact us at&nbsp;<b>info@alertfrontier.com</b></p>

<p>&nbsp;</p>

<p><b>SUMMARY OF KEY POINTS</b></p>
<p>&nbsp;</p>

<p><b><i>This summary provides key points from our privacy
notice, but you can find out more details about any of these topics by clicking
the link following each key point or by using our&nbsp;</i></b><a
href="#_TABLE_OF_CONTENTS"><b><i>table of contents</i></b></a><b><i>&nbsp;below
to find the section you are looking for.</i></b></p>

<p>&nbsp;</p>

<p><b>What personal information do we process?</b>&nbsp;When
you visit, use, or navigate our Services, we may process personal information
depending on how you interact with us and the Services, the choices you make,
and the products and features you use. Learn more about&nbsp;<a
href="#_Personal_information_you">personal information you disclose to us</a>.</p>

<p>&nbsp;</p>

<p><b>Do we process any sensitive personal information? </b>We
do not process sensitive personal information.</p>

<p>&nbsp;</p>

<p><b>Do we receive any information from third parties? </b>We
do not receive any information from third parties.</p>

<p>&nbsp;</p>

<p><b>How do we process your information?</b>&nbsp;We process
your information to provide, improve, and administer our Services, communicate
with you, for security and fraud prevention, and to comply with law. We may
also process your information for other purposes with your consent. We process
your information only when we have a valid legal reason to do so. Learn more
about&nbsp;<a href="#_2._HOW_DO">how we process your information</a>.</p>

<p>&nbsp;</p>

<p><b>In what situations and with which types of parties do we
share personal information?</b>&nbsp;We may share information in specific
situations and with specific categories of third parties. Learn more
about&nbsp;<a href="#_3._WHEN_AND">when and with whom we share your personal
information</a>.</p>

<p>&nbsp;</p>

<p><b>How do we keep your information safe?</b>&nbsp;We have
organizational and technical processes and procedures in place to protect your
personal information. However, no electronic transmission over the internet or
information storage technology can be guaranteed to be 100% secure, so we
cannot promise or guarantee that hackers, cybercriminals, or other unauthorized
third parties will not be able to defeat our security and improperly collect,
access, steal, or modify your information. Learn more about&nbsp;<a
href="#_6._HOW_DO">how we keep your information safe</a>.</p>

<p>&nbsp;</p>

<p><b>What are your rights?</b>&nbsp;Depending on where you are
located geographically, the applicable privacy law may mean you have certain rights
regarding your personal information. Learn more about&nbsp;<a
href="#_8._WHAT_ARE">your privacy rights</a>.</p>

<p>&nbsp;</p>

<p><b>How do you exercise your rights?</b> The easiest way to exercise your rights is by submitting a 
<Link to="/data-subject-access-request" className="special-link"> data subject access request</Link>, 
or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
</p>


<p>&nbsp;</p>

<p>Want to learn more about what we do with any information we
collect?&nbsp;<a href="#_TABLE_OF_CONTENTS">Review the privacy notice in full</a>.</p>

<p>&nbsp;</p>

<h1><a id="_TABLE_OF_CONTENTS"></a>TABLE OF CONTENTS</h1>
  
<p>&nbsp;</p>

<p><a href="#_1._WHAT_INFORMATION">1. WHAT INFORMATION DO WE
COLLECT?</a></p>

<p><a href="#_2._HOW_DO">2. HOW DO WE PROCESS YOUR INFORMATION?</a></p>

<p><a href="#_3._WHEN_AND">3. WHEN AND WITH WHOM DO WE SHARE
YOUR PERSONAL INFORMATION?</a></p>

<p><a href="#_4._DO_WE">4. DO WE USE COOKIES AND OTHER TRACKING
TECHNOLOGIES?</a></p>

<p><a href="#_5._HOW_LONG">5. HOW LONG DO WE KEEP YOUR
INFORMATION?</a></p>

<p><a href="#_6._HOW_DO">6. HOW DO WE KEEP YOUR INFORMATION
SAFE?</a></p>

<p><a href="#_7._DO_WE">7. DO WE COLLECT INFORMATION FROM
MINORS?</a></p>

<p><a href="#_8._WHAT_ARE">8. WHAT ARE YOUR PRIVACY RIGHTS?</a></p>

<p><a href="#_9._CONTROLS_FOR">9. CONTROLS FOR DO-NOT-TRACK
FEATURES</a></p>

<p><a href="#_10._DO_UNITED">10. DO UNITED STATES RESIDENTS
HAVE SPECIFIC PRIVACY RIGHTS?</a></p>

<p><a href="#_11._DO_WE">11. DO WE MAKE UPDATES TO THIS NOTICE?</a></p>

<p><a href="#_12._HOW_CAN">12. HOW CAN YOU CONTACT US ABOUT
THIS NOTICE?</a></p>

<p><a href="#_13._HOW_CAN">13. HOW CAN YOU REVIEW, UPDATE, OR
DELETE THE DATA WE COLLECT FROM YOU?</a></p>

<p>&nbsp;</p>

<h2><a id="_1._WHAT_INFORMATION"></a>1. WHAT INFORMATION DO WE COLLECT?</h2>

<p>&nbsp;</p>

<h3><a id="_Personal_information_you"></a>Personal information you disclose
to us</h3>

<p>&nbsp;</p>

<p><b><i>In Short:&nbsp;</i></b><i>We collect personal
information that you provide to us.</i></p>

<p>&nbsp;</p>

<p>We collect personal information that you voluntarily provide
to us when you register on the Services, express an interest in obtaining
information about us or our products and Services, when you participate in
activities on the Services, or otherwise when you contact us.</p>

<p>&nbsp;</p>

<p><b>Personal Information Provided by You.</b>&nbsp;The
personal information that we collect depends on the context of your
interactions with us and the Services, the choices you make, and the products
and features you use. The personal information we collect may include the
following:</p>

<ul>
 <li>names</li>
</ul>

<ul>
 <li>phone numbers</li>
</ul>

<ul>
 <li>email addresses</li>
</ul>

<ul>
 <li>usernames</li>
</ul>

<ul>
 <li>passwords</li>
</ul>

<ul>
 <li>job titles</li>
</ul>

<ul>
 <li>contact or authentication data</li>
</ul>

<p><b>Sensitive Information.</b></p>

<p>We do not process sensitive information.</p>

<p>&nbsp;</p>

<p><b>Application Data.</b>&nbsp;If you use our application(s),
we also may collect the following information if you choose to provide us with
access or permission:</p>

<ul>
 <li><i>Geolocation Information.</i>&nbsp;We may request access
     or permission to track location-based information from your mobile device,
     either continuously or while you are using our mobile application(s), to
     provide certain location-based services. If you wish to change our access
     or permissions, you may do so in your device's settings.</li>
</ul>

<ul>
 <li><i>Mobile Device Data.</i>&nbsp;We automatically collect
     device information (such as your mobile device ID, model, and
     manufacturer), operating system, version information and system
     configuration information, device and application identification numbers,
     browser type and version, hardware model Internet service provider and/or
     mobile carrier, and Internet Protocol (IP) address (or proxy server). If
     you are using our application(s), we may also collect information about
     the phone network associated with your mobile device, your mobile device’s
     operating system or platform, the type of mobile device you use, your
     mobile device’s unique device ID, and information about the features of
     our application(s) you accessed.</li>
</ul>

<ul>
 <li><i>Push Notifications.</i>&nbsp;We may request to send you
     push notifications regarding your account or certain features of the
     application(s). If you wish to opt out from receiving these types of
     communications, you may turn them off in your device's settings.</li>
</ul>

<p>This information is primarily needed to maintain the
security and operation of our application(s), for troubleshooting, and for our
internal analytics and reporting purposes.</p>

<p>&nbsp;</p>

<p>All personal information that you provide to us must be
true, complete, and accurate, and you must notify us of any changes to such
personal information.</p>

<p>&nbsp;</p>

<p><b>Information automatically collected</b></p>

<p>&nbsp;</p>

<p><b><i>In Short:&nbsp;</i></b><i>Some information — such as
your Internet Protocol (IP) address and/or browser and device characteristics —
is collected automatically when you visit our Services.</i></p>

<p>&nbsp;</p>

<p>We automatically collect certain information when you visit,
use, or navigate the Services. This information does not reveal your specific
identity (like your name or contact information) but may include device and
usage information, such as your IP address, browser and device characteristics,
operating system, language preferences, referring URLs, device name, country,
location, information about how and when you use our Services, and other
technical information. This information is primarily needed to maintain the
security and operation of our Services, and for our internal analytics and
reporting purposes.</p>

<p>&nbsp;</p>

<p>Like many businesses, we also collect information through
cookies and similar technologies.</p>

<p>&nbsp;</p>

<p>The information we collect includes:</p>

<ul>
 <li><i>Log and Usage Data.</i>&nbsp;Log and usage data is
     service-related, diagnostic, usage, and performance information our
     servers automatically collect when you access or use our Services and
     which we record in log files. Depending on how you interact with us, this
     log data may include your IP address, device information, browser type,
     and settings and information about your activity in the
     Services&nbsp;(such as the date/time stamps associated with your usage,
     pages and files viewed, searches, and other actions you take such as which
     features you use), device event information (such as system activity,
     error reports (sometimes called &quot;crash dumps&quot;), and hardware
     settings).</li>
</ul>

<ul>
 <li><i>Device Data.</i>&nbsp;We collect device data such as
     information about your computer, phone, tablet, or other device you use to
     access the Services. Depending on the device used, this device data may
     include information such as your IP address (or proxy server), device and
     application identification numbers, location, browser type, hardware
     model, Internet service provider and/or mobile carrier, operating system,
     and system configuration information.</li>
</ul>

<p><b>&nbsp;</b></p>
  
  
<h2><a id="_2._HOW_DO"></a>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>

<p>&nbsp;</p>

<p><b><i>In Short:&nbsp;</i></b><i>We process your information
to provide, improve, and administer our Services, communicate with you, for
security and fraud prevention, and to comply with law. We may also process your
information for other purposes with your consent.</i></p>

<p>&nbsp;</p>

<p><b>We process your personal information for a variety of
reasons, depending on how you interact with our Services, including:</b></p>

<ul>
 <li><b>To facilitate account creation and authentication and
     otherwise manage user accounts.&nbsp;</b>We may process your information
     so you can create and log in to your account, as well as keep your account
     in working order.</li>
</ul>

<ul>
 <li><b>To deliver and facilitate delivery of services to the
     user.&nbsp;</b>We may process your information to provide you with the
     requested service.</li>
</ul>

<ul>
 <li><b>To send administrative information to you.&nbsp;</b>We
     may process your information to send you details about our products and
     services, changes to our terms and policies, and other similar
     information.</li>
</ul>

<ul>
 <li><b>To evaluate and improve our Services, products,
     marketing, and your experience.</b>&nbsp;We may process your information
     when we believe it is necessary to identify usage trends, determine the
     effectiveness of our promotional campaigns, and to evaluate and improve
     our Services, products, marketing, and your experience.</li>
</ul>

<ul>
 <li><b>To identify usage trends.</b>&nbsp;We may process
     information about how you use our Services to better understand how they
     are being used so we can improve them.</li>
</ul>

<p>&nbsp;</p>

<h2><a id="_3._WHEN_AND"></a>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
INFORMATION?</h2>

<p>&nbsp;</p>

<p><b><i>In Short:</i></b><i>&nbsp;We may share information in
specific situations described in this section and/or with the following
categories of third parties.</i></p>

<p>&nbsp;</p>

<p><b>Vendors, Consultants, and Other Third-Party Service
Providers.</b>&nbsp;We may share your data with third-party vendors, service
providers, contractors, or agents (&quot;<b>third parties</b>&quot;) who
perform services for us or on our behalf and require access to such information
to do that work.</p>

<p>We have contracts in place with our third parties, which are
designed to help safeguard your personal information. This means that they
cannot do anything with your personal information unless we have instructed
them to do it. They will also not share your personal information with any
organization apart from us. They also commit to protect the data they hold on
our behalf and to retain it for the period we instruct.</p>

<p>The categories of third parties we may share personal
information with are as follows:</p>

<ul>
 <li>Performance Monitoring Tools</li>
</ul>

<ul>
 <li>Testing Tools</li>
</ul>

<ul>
 <li>Data Analytics Services</li>
</ul>

<ul>
 <li>Communication &amp; Collaboration Tools</li>
</ul>

<p>&nbsp;</p>

<p>We also may need to share your personal information in the
following situations:</p>

<ul>
 <li><b>Business Transfers.</b>&nbsp;We may share or transfer
     your information in connection with, or during negotiations of, any
     merger, sale of company assets, financing, or acquisition of all or a
     portion of our business to another company.</li>
</ul>

<ul>
 <li><b>When we use Google Maps Platform APIs.</b>&nbsp;We may
     share your information with certain Google Maps Platform APIs (e.g.,
     Google Maps API, Places API).</li>
</ul>

<p>We use certain Google Maps Platform APIs to retrieve certain
information when you make location-specific requests. This includes: (Opt-in)
Dispatched User Location for Status Updates; and other similar information. A
full list of what we use information for can be found in this section and in
the previous section titled &quot;<a href="#_2._HOW_DO">HOW DO WE PROCESS YOUR
INFORMATION?</a>&quot; We obtain and store on your device (&quot;cache&quot;)
your location. You may revoke your consent anytime by contacting us at the
contact details provided at the end of this document.</p>

<p>The Google Maps Platform APIs that we use store and access
cookies and other information on your devices. If you are a user currently in
the European Economic Area (EU countries, Iceland, Liechtenstein, and Norway)
or the United Kingdom, please take a look at our Cookie Notice.</p>

<p>&nbsp;</p>

<h2><a id="_4._DO_WE"></a>4. DO WE USE COOKIES AND OTHER TRACKING
TECHNOLOGIES?</h2>

<p>&nbsp;</p>

<p><b><i>In Short:</i></b><i>&nbsp;We may use cookies and other
tracking technologies to collect and store your information.</i></p>

<p>&nbsp;</p>

<p>We may use cookies and similar tracking technologies (like
web beacons and pixels) to access or store information. Specific information
about how we use such technologies and how you can refuse certain cookies is
set out in our Cookie Notice.</p>

<p>&nbsp;</p>

<h2><a id="_5._HOW_LONG"></a>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

<p>&nbsp;</p>

<p><b><i>In Short:&nbsp;</i></b><i>We keep your information for
as long as necessary to fulfill the purposes outlined in this privacy notice
unless otherwise required by law.</i></p>

<p>&nbsp;</p>

<p>We will only keep your personal information for as long as
it is necessary for the purposes set out in this privacy notice, unless a
longer retention period is required or permitted by law (such as tax,
accounting, or other legal requirements).</p>

<p>No purpose in this notice will require us keeping your
personal information for longer than twelve (12) months past the termination of
the user's account.</p>

<p>&nbsp;</p>

<p>When we have no ongoing legitimate business need to process your
personal information, we will either delete or anonymize such information, or,
if this is not possible (for example, because your personal information has
been stored in backup archives), then we will securely store your personal
information and isolate it from any further processing until deletion is
possible.</p>

<p>&nbsp;</p>

<h2><a id="_6._HOW_DO"></a>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

<p>&nbsp;</p>

<p><b><i>In Short:&nbsp;</i></b><i>We aim to protect your
personal information through a system of organizational and technical security
measures.</i></p>

<p>&nbsp;</p>

<p>We have implemented appropriate and reasonable technical and
organizational security measures designed to protect the security of any
personal information we process. However, despite our safeguards and efforts to
secure your information, no electronic transmission over the Internet or
information storage technology can be guaranteed to be 100% secure, so we
cannot promise or guarantee that hackers, cybercriminals, or other unauthorized
third parties will not be able to defeat our security and improperly collect,
access, steal, or modify your information. Although we will do our best to
protect your personal information, transmission of personal information to and
from our Services is at your own risk. You should only access the Services
within a secure environment.</p>

<p>&nbsp;</p>

<h2><a id="_7._DO_WE"></a>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>

<p>&nbsp;</p>

<p><b><i>In Short:</i></b><i>&nbsp;We do not knowingly collect
data from or market to children under 18 years of age.</i></p>

<p>&nbsp;</p>

<p>We do not knowingly solicit data from or market to children
under 18 years of age. By using the Services, you represent that you are at
least 18 or that you are the parent or guardian of such a minor and consent to
such minor dependent’s use of the Services. If we learn that personal
information from users less than 18 years of age has been collected, we will
deactivate the account and take reasonable measures to promptly delete such
data from our records. If you become aware of any data we may have collected
from children under age 18, please contact us at info@alertfrontier.com.</p>

<p>&nbsp;</p>

<h2><a id="_8._WHAT_ARE"></a>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

<p>&nbsp;</p>

<p><b><i>In Short: </i></b><i>You may review, change, or
terminate your account at any time.</i></p>

<p>&nbsp;</p>

<p><b><u>Withdrawing your consent:</u></b>&nbsp;If we are
relying on your consent to process your personal information,</p>

<p>which may be express and/or implied consent depending on the
applicable law,</p>

<p>you have the right to withdraw your consent at any time. You
can withdraw your consent at any time by contacting us by using the contact
details provided in the section &quot;<a href="#_12._HOW_CAN">HOW CAN YOU
CONTACT US ABOUT THIS NOTICE?</a>&quot; below.</p>

<p>&nbsp;</p>

<p>However, please note that this will not affect the
lawfulness of the processing before its withdrawal nor,</p>

<p>when applicable law allows, will it affect the processing of
your personal information conducted in reliance on lawful processing grounds
other than consent.</p>

<p>&nbsp;</p>

<p><b>Account Information</b></p>

<p>&nbsp;</p>

<p>If you would at any time like to review or change the
information in your account or terminate your account, you can:</p>

<ul>
 <li>Contact us using the contact information provided.</li>
</ul>

<ul>
 <li>Log in to your account settings and update your user
     account.</li>
</ul>

<p>Upon your request to terminate your account, we will
deactivate or delete your account and information from our active databases.
However, we may retain some information in our files to prevent fraud,
troubleshoot problems, assist with any investigations, enforce our legal terms
and/or comply with applicable legal requirements.</p>

<p>&nbsp;</p>

<p><b><u>Cookies and similar technologies:</u></b>&nbsp;Most
Web browsers are set to accept cookies by default. If you prefer, you can
usually choose to set your browser to remove cookies and to reject cookies. If
you choose to remove cookies or reject cookies, this could affect certain
features or services of our Services.</p>

<p>&nbsp;</p>

<p>If you have questions or comments about your privacy rights,
you may email us at info@alertfrontier.com.</p>

<p>&nbsp;</p>

<h2><a id="_9._CONTROLS_FOR"></a>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

<p>&nbsp;</p>

<p>Most web browsers and some mobile operating systems and
mobile applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting
you can activate to signal your privacy preference not to have data about your
online browsing activities monitored and collected. At this stage no uniform
technology standard for recognizing and implementing DNT signals has been
finalized. As such, we do not currently respond to DNT browser signals or any
other mechanism that automatically communicates your choice not to be tracked online.
If a standard for online tracking is adopted that we must follow in the future,
we will inform you about that practice in a revised version of this privacy
notice.</p>

<p>&nbsp;</p>


<h2><a id="_10._DO_UNITED"></a>10. DO UNITED STATES RESIDENTS HAVE SPECIFIC
PRIVACY RIGHTS?</h2>

<p>&nbsp;</p>

<p><b><i>In Short:&nbsp;</i></b><i>If you are a resident of
California, Colorado, Connecticut, Utah or Virginia, you are granted specific
rights regarding access to your personal information.</i></p>

<p>&nbsp;</p>

<p><b>What categories of personal information do we collect?</b></p>

<p>&nbsp;</p>

<p>We have collected the following categories of personal
information in the past twelve (12) months:</p>

<p>&nbsp;</p>

<table>
 <tr>
  <td>
  <p><b>Category</b></p>
  </td>
  <td>
  <p><b>Examples</b></p>
  </td>
  <td>
  <p><b>Collected</b></p>
  </td>
 </tr>
 <tr>
  <td>
  <p>A.
  Identifiers</p>
  </td>
  <td>
  <p>Contact details,
  such as real name, alias, postal address, telephone or mobile contact number,
  unique personal identifier, online identifier, Internet Protocol address,
  email address, and account name</p>
  </td>
  <td>
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>B. Personal
  information as defined in the California Customer Records statute</p>
  </td>
  <td>
  <p>Name, contact
  information, education, employment, employment history, and financial
  information</p>
  </td>
  <td>
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>C. Protected classification characteristics under state or
  federal law</p>
  </td>
  <td>
  <p>Gender and
  date of birth</p>
  </td>
  <td>
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>D. Commercial information</p>
  </td>
  <td>
  <p>Transaction
  information, purchase history, financial details, and payment information</p>
  </td>
  <td>
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>E. Biometric information</p>
  </td>
  <td>
  <p>Fingerprints
  and voiceprints</p>
  </td>
  <td>
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>F. Internet or other similar network activity</p>
  </td>
  <td>
  <p>Browsing history, search history, online behavior,
  interest data, and interactions with our and other websites, applications,
  systems, and advertisements</p>
  </td>
  <td>
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>G. Geolocation data</p>
  </td>
  <td>
  <p>Device
  location</p>
  </td>
  <td>
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>H. Audio, electronic, visual, thermal, olfactory, or
  similar information</p>
  </td>
  <td>
  <p>Images and
  audio, video or call recordings created in connection with our business
  activities</p>
  </td>
  <td>
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>I. Professional or employment-related information</p>
  </td>
  <td>
  <p>Business
  contact details in order to provide you our Services at a business level or
  job title, work history, and professional qualifications if you apply for a
  job with us</p>
  </td>
  <td>
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>J. Education Information</p>
  </td>
  <td>
  <p>Student
  records and directory information</p>
  </td>
  <td >
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>K. Inferences drawn from collected personal information</p>
  </td>
  <td>
  <p>Inferences
  drawn from any of the collected personal information listed above to create a
  profile or summary about, for example, an individual’s preferences and
  characteristics</p>
  </td>
  <td>
  <p>NO</p>
  </td>
 </tr>
 <tr>
  <td>
  <p>L. Sensitive personal Information</p>
  </td>
  <td>
  <p>&nbsp;</p>
  </td>
  <td >
  <p>NO</p>
  </td>
 </tr>
</table>

<p>&nbsp;</p>

<p>We may also collect other personal information outside of
these categories through instances where you interact with us in person,
online, or by phone or mail in the context of:</p>

<ul>
 <li>Receiving help through our customer support channels;</li>
</ul>

<ul>
 <li>Participation in customer surveys or contests; and</li>
</ul>

<ul>
 <li>Facilitation in the delivery of our Services and to
     respond to your inquiries.</li>
</ul>

<p><b>How do we use and share your personal information?</b></p>

<p>&nbsp;</p>

<p>Learn about how we use your personal information in the
section, &quot;<a href="#_2._HOW_DO">HOW DO WE PROCESS YOUR INFORMATION?</a>&quot;</p>

<p>&nbsp;</p>

<p><b>Will your information be shared with anyone else?</b></p>

<p>&nbsp;</p>

<p>We may disclose your personal information with our service
providers pursuant to a written contract between us and each service provider.
Learn more about how we disclose personal information to in the section, &quot;<a
href="#_3._WHEN_AND">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>&quot;</p>

<p>&nbsp;</p>

<p>We may use your personal information for our own business
purposes, such as for undertaking internal research for technological
development and demonstration. This is not considered to be &quot;selling&quot;
of your personal information.</p>

<p>&nbsp;</p>

<p>We have disclosed the following categories of personal
information to third parties for a business or commercial purpose in the
preceding twelve (12) months:</p>

<p>The categories of third parties to whom we disclosed
personal information for a business or commercial purpose can be found under
&quot;<a href="#_3._WHEN_AND">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
INFORMATION?</a>&quot;</p>

<p>&nbsp;</p>

<p>We have sold or shared the following categories of personal
information to third parties in the preceding twelve (12) months:</p>

<p>The categories of third parties to whom we sold personal
information are:</p>

<p>&nbsp;</p>

<p><b>California Residents</b></p>

<p>&nbsp;</p>

<p>California Civil Code Section 1798.83, also known as the
&quot;Shine The Light&quot; law permits our users who are California residents
to request and obtain from us, once a year and free of charge, information
about categories of personal information (if any) we disclosed to third parties
for direct marketing purposes and the names and addresses of all third parties
with which we shared personal information in the immediately preceding calendar
year. If you are a California resident and would like to make such a request,
please submit your request in writing to us using the contact information
provided below.</p>

<p>&nbsp;</p>

<p>If you are under 18 years of age, reside in California, and
have a registered account with the Services, you have the right to request
removal of unwanted data that you publicly post on the Services. To request
removal of such data, please contact us using the contact information provided
below and include the email address associated with your account and a
statement that you reside in California. We will make sure the data is not
publicly displayed on the Services, but please be aware that the data may not
be completely or comprehensively removed from all our systems (e.g., backups,
etc.).</p>

<p>&nbsp;</p>

<p><b><u>CCPA Privacy Notice</u></b></p>

<p>&nbsp;</p>

<p>This section applies only to California residents. Under the
California Consumer Privacy Act (CCPA), you have the rights listed below.</p>

<p>&nbsp;</p>

<p>The California Code of
Regulations defines a &quot;residents&quot; as:</p>

<p>(1) every individual who is in the State of California for
other than a temporary or transitory purpose and</p>

<p>(2) every individual who is domiciled in the State of
California who is outside the State of California for a temporary or transitory
purpose</p>

<p>&nbsp;</p>

<p>All other individuals are
defined as &quot;non-residents.&quot;</p>

<p>If this definition of &quot;resident&quot; applies to you,
we must adhere to certain rights and obligations regarding your personal
information.</p>

<p>&nbsp;</p>

<p><b>Your rights with respect to your personal data</b></p>

<p>&nbsp;</p>

<p><u>Right to request deletion of the data — Request to delete</u></p>

<p>&nbsp;</p>

<p>You can ask for the deletion of your personal information.
If you ask us to delete your personal information, we will respect your request
and delete your personal information, subject to certain exceptions provided by
law, such as (but not limited to) the exercise by another consumer of his or
her right to free speech, our compliance requirements resulting from a legal
obligation, or any processing that may be required to protect against illegal
activities.</p>

<p>&nbsp;</p>

<p><u>Right to be informed — Request to know</u></p>

<p>&nbsp;</p>

<p>Depending on the circumstances, you have a right to know:</p>

<ul>
 <li>whether we collect and use your personal information;</li>
</ul>

<ul>
 <li>the categories of personal information that we collect;</li>
</ul>

<ul>
 <li>the purposes for which the collected personal information
     is used;</li>
</ul>

<ul>
 <li>whether we sell or share personal information to third
     parties;</li>
</ul>

<ul>
 <li>the categories of personal information that we sold,
     shared, or disclosed for a business purpose;</li>
</ul>

<ul>
 <li>the categories of third parties to whom the personal
     information was sold, shared, or disclosed for a business purpose;</li>
</ul>

<ul>
 <li>the business or commercial purpose for collecting,
     selling, or sharing personal information; and</li>
</ul>

<ul>
 <li>the specific pieces of personal information we collected
     about you.</li>
</ul>

<p>In accordance with applicable law, we are not obligated to
provide or delete consumer information that is de-identified in response to a
consumer request or to re-identify individual data to verify a consumer
request.</p>

<p>&nbsp;</p>

<p><u>Right to Non-Discrimination for the Exercise of a
Consumer’s Privacy Rights</u></p>

<p>&nbsp;</p>

<p>We will not discriminate against you if you exercise your
privacy rights.</p>

<p>&nbsp;</p>

<p><u>Right to Limit Use and Disclosure of Sensitive Personal
Information</u></p>

<p>&nbsp;</p>

<p>We do not process consumer's sensitive personal information.</p>

<p>&nbsp;</p>

<p><u>Verification process</u></p>

<p>&nbsp;</p>

<p>Upon receiving your request, we will need to verify your
identity to determine you are the same person about whom we have the
information in our system. These verification efforts require us to ask you to
provide information so that we can match it with information you have
previously provided us. For instance, depending on the type of request you
submit, we may ask you to provide certain information so that we can match the
information you provide with the information we already have on file, or we may
contact you through a communication method (e.g., phone or email) that you have
previously provided to us. We may also use other verification methods as the
circumstances dictate.</p>

<p>&nbsp;</p>

<p>We will only use personal information provided in your
request to verify your identity or authority to make the request. To the extent
possible, we will avoid requesting additional information from you for the
purposes of verification. However, if we cannot verify your identity from the
information already maintained by us, we may request that you provide
additional information for the purposes of verifying your identity and for
security or fraud-prevention purposes. We will delete such additionally provided
information as soon as we finish verifying you.</p>

<p>&nbsp;</p>

<p><u>Other privacy rights</u></p>

<ul>
 <li>You may object to the processing of your personal
     information.</li>
</ul>

<ul>
 <li>You may request correction of your personal data if it is incorrect
     or no longer relevant, or ask to restrict the processing of the
     information.</li>
</ul>

<ul>
 <li>You can designate an authorized agent to make a request
     under the CCPA on your behalf. We may deny a request from an authorized
     agent that does not submit proof that they have been validly authorized to
     act on your behalf in accordance with the CCPA.</li>
</ul>

<p>You can opt out from the selling or sharing of your personal
information by disabling cookies in Cookie Preference Settings and clicking on
the Do Not Sell or Share My Personal Information link on our homepage.</p>

<p>&nbsp;</p>

<p>To exercise these rights, you can contact us by submitting
a&nbsp;<Link to="/data-subject-access-request" className="special-link"> data subject access request</Link>, by email at <a
href="mailto:info@alertfrontier.com">info@alertfrontier.com</a>, or by
referring to the contact details at the bottom of this document. If you have a
complaint about how we handle your data, we would like to hear from you.</p>

<p>&nbsp;</p>

<p><b>Colorado Residents</b></p>

<p>&nbsp;</p>

<p>This section applies only to Colorado residents. Under the
Colorado Privacy Act (CPA), you have the rights listed below. However, these
rights are not absolute, and in certain cases, we may decline your request as
permitted by law.</p>

<ul>
 <li>Right to be informed whether or not we are processing your
     personal data</li>
</ul>

<ul>
 <li>Right to access your personal data</li>
</ul>

<ul>
 <li>Right to correct inaccuracies in your personal data</li>
</ul>

<ul>
 <li>Right to request deletion of your personal data</li>
</ul>

<ul>
 <li>Right to obtain a copy of the personal data you previously
     shared with us</li>
</ul>

<ul>
 <li>Right to opt out of the processing of your personal data
     if it is used for targeted advertising, the sale of personal data, or
     profiling in furtherance of decisions that produce legal or similarly
     significant effects (&quot;profiling&quot;)</li>
</ul>

<p>We sell personal data to third parties or process personal
data for targeted advertising. You can opt out from the selling of your
personal data, targeted advertising, or profiling by disabling cookies in
Cookie Preference Settings.</p>

<p>To submit a request to exercise any of the other rights
described above, please email <a href="mailto:info@alertfrontier.com">info@alertfrontier.com</a>
or submit a&nbsp;<Link to="/data-subject-access-request" className="special-link"> data subject access request</Link>.</p>

<p>&nbsp;</p>

<p>If we decline to take action regarding your request and you
wish to appeal our decision, please email us at <a
href="mailto:info@alertfrontier.com">info@alertfrontier.com</a>. Within
forty-five (45) days of receipt of an appeal, we will inform you in writing of
any action taken or not taken in response to the appeal, including a written
explanation of the reasons for the decisions.</p>

<p>&nbsp;</p>

<p><b>Connecticut Residents</b></p>

<p>&nbsp;</p>

<p>This section applies only to Connecticut residents. Under
the Connecticut Data Privacy Act (CTDPA), you have the rights listed below.
However, these rights are not absolute, and in certain cases, we may decline
your request as permitted by law.</p>

<ul>
 <li>Right to be informed whether or not we are processing your
     personal data</li>
</ul>

<ul>
 <li>Right to access your personal data</li>
</ul>

<ul>
 <li>Right to correct inaccuracies in your personal data</li>
</ul>

<ul>
 <li>Right to request deletion of your personal data</li>
</ul>

<ul>
 <li>Right to obtain a copy of the personal data you previously
     shared with us</li>
</ul>

<ul>
 <li>Right to opt out of the processing of your personal data
     if it is used for targeted advertising, the sale of personal data, or
     profiling in furtherance of decisions that produce legal or similarly
     significant effects (&quot;profiling&quot;)</li>
</ul>

<p>We sell personal data to third parties or process personal
data for targeted advertising. You can opt out from the selling of your
personal data, targeted advertising, or profiling by disabling cookies in
Cookie Preference Settings.</p>

<p>To submit a request to exercise any of the other rights
described above, please email <a href="mailto:info@alertfrontier.com">info@alertfrontier.com</a>
or submit a&nbsp;<Link to="/data-subject-access-request" className="special-link"> data subject access request</Link>.</p>

<p>&nbsp;</p>

<p>If we decline to take action regarding your request and you
wish to appeal our decision, please email us at info@alertfrontier.com. Within
sixty (60) days of receipt of an appeal, we will inform you in writing of any
action taken or not taken in response to the appeal, including a written
explanation of the reasons for the decisions.</p>

<p>&nbsp;</p>

<p><b>Utah Residents</b></p>

<p>&nbsp;</p>

<p>This section applies only to Utah residents. Under the Utah
Consumer Privacy Act (UCPA), you have the rights listed below. However, these
rights are not absolute, and in certain cases, we may decline your request as
permitted by law.</p>

<ul>
 <li>Right to be informed whether or not we are processing your
     personal data</li>
</ul>

<ul>
 <li>Right to access your personal data</li>
</ul>

<ul>
 <li>Right to request deletion of your personal data</li>
</ul>

<ul>
 <li>Right to obtain a copy of the personal data you previously
     shared with us</li>
</ul>

<ul>
 <li>Right to opt out of the processing of your personal data
     if it is used for targeted advertising or the sale of personal data</li>
</ul>

<p>We sell personal data to third parties or process personal
data for targeted advertising. You can opt out from the selling of your
personal data or targeted advertising by disabling cookies in Cookie Preference
Settings.</p>

<p>To submit a request to exercise any of the other rights
described above, please email <a href="mailto:info@alertfrontier.com">info@alertfrontier.com</a>
or submit a&nbsp;<Link to="/data-subject-access-request" className="special-link"> data subject access request</Link>.</p>

<p>&nbsp;</p>

<p><b>Virginia Residents</b></p>

<p>&nbsp;</p>

<p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>

<p>&nbsp;</p>

<p>&quot;Consumer&quot; means a natural person who is a
resident of the Commonwealth acting only in an individual or household context.
It does not include a natural person acting in a commercial or employment
context.</p>

<p>&nbsp;</p>

<p>&quot;Personal data&quot; means any information that is
linked or reasonably linkable to an identified or identifiable natural person.
&quot;Personal data&quot; does not include de-identified data or publicly
available information.</p>

<p>&nbsp;</p>

<p>&quot;Sale of personal data&quot; means the exchange of
personal data for monetary consideration.</p>

<p>&nbsp;</p>

<p>If this definition of &quot;consumer&quot; applies to you,
we must adhere to certain rights and obligations regarding your personal data.</p>

<p>&nbsp;</p>

<p><u>Your rights with respect to your personal data</u></p>

<ul>
 <li>Right to be informed whether or not we are processing your
     personal data</li>
</ul>

<ul>
 <li>Right to access your personal data</li>
</ul>

<ul>
 <li>Right to correct inaccuracies in your personal data</li>
</ul>

<ul>
 <li>Right to request deletion of your personal data</li>
</ul>

<ul>
 <li>Right to obtain a copy of the personal data you previously
     shared with us</li>
</ul>

<ul>
 <li>Right to opt out of the processing of your personal data
     if it is used for targeted advertising, the sale of personal data, or
     profiling in furtherance of decisions that produce legal or similarly
     significant effects (&quot;profiling&quot;)</li>
</ul>

<p>We sell personal data to third parties or process personal
data for targeted advertising. Please see the following section to find out how
you can opt out from further selling or sharing of your personal data for
targeted advertising or profiling purposes.</p>

<p>&nbsp;</p>

<p><u>Exercise your rights provided under the Virginia VCDPA</u></p>

<p>&nbsp;</p>

<p>You can opt out from the selling of your personal data,
targeted advertising, or profiling by disabling cookies in Cookie Preference
Settings.</p>

<p>You may contact us by email at <a
href="mailto:info@alertfrontier.com">info@alertfrontier.com</a> or submit
a&nbsp;<Link to="/data-subject-access-request" className="special-link"> data subject access request</Link>.</p>

<p>&nbsp;</p>

<p>If you are using an authorized agent to exercise your
rights, we may deny a request if the authorized agent does not submit proof
that they have been validly authorized to act on your behalf.</p>

<p>&nbsp;</p>

<p><u>Verification process</u></p>

<p>&nbsp;</p>

<p>We may request that you provide additional information
reasonably necessary to verify you and your consumer's request. If you submit
the request through an authorized agent, we may need to collect additional
information to verify your identity before processing your request.</p>

<p>&nbsp;</p>

<p>Upon receiving your request, we will respond without undue
delay, but in all cases, within forty-five (45) days of receipt. The response
period may be extended once by forty-five (45) additional days when reasonably
necessary. We will inform you of any such extension within the initial 45-day
response period, together with the reason for the extension.</p>

<p>&nbsp;</p>

<p><u>Right to appeal</u></p>

<p>&nbsp;</p>

<p>If we decline to take action regarding your request, we will
inform you of our decision and reasoning behind it. If you wish to appeal our
decision, please email us at info@alertfrontier.com. Within sixty (60) days of
receipt of an appeal, we will inform you in writing of any action taken or not
taken in response to the appeal, including a written explanation of the reasons
for the decisions. If your appeal is denied, you may contact the&nbsp;<a
href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
target="_blank">Attorney General to submit a complaint</a>.</p>

<p>&nbsp;</p>

<h2><a id="_11._DO_WE"></a>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>

<p>&nbsp;</p>

<p><b><i>In Short:&nbsp;</i></b><i>Yes, we will update this
notice as necessary to stay compliant with relevant laws.</i></p>

<p>&nbsp;</p>

<p>We may update this privacy notice from time to time. The updated
version will be indicated by an updated &quot;Revised&quot; date and the
updated version will be effective as soon as it is accessible. If we make
material changes to this privacy notice, we may notify you either by
prominently posting a notice of such changes or by directly sending you a
notification. We encourage you to review this privacy notice frequently to be
informed of how we are protecting your information.</p>

<p>&nbsp;</p>

<h2><a id="_12._HOW_CAN"></a>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

<p>&nbsp;</p>

<p>If you have questions or comments about this notice, you may
email us at info@alertfrontier.com</p>

<p>&nbsp;</p>

<h2><a id="_13._HOW_CAN"></a>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
DATA WE COLLECT FROM YOU?</h2>

<p>&nbsp;</p>

<p>Based on the applicable laws of your country, you may have
the right to request access to the personal information we collect from you,
change that information, or delete it.</p>

<p>To request to review, update, or delete your personal
information, please fill out and submit a <Link to="/data-subject-access-request" className="special-link"> data subject access request</Link></p>

<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
  </div>
  </div>
  

);


};

export default PrivacyPolicy;
