import React, { useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import './HomePage.css';
import './Common.css';
import decideImage from './../img/dispatch.png';
import alertImage from './../img/alert.png'; // Assuming list.png is the top image for "alert"
import alertImageBack from './../img/coats.png'; // The new landscape image for "alert"
import respondImage from './../img/respond.png';
import respondImageBack from './../img/bifd2.jpg'; // The new landscape image for "alert"

const HomePage: React.FC = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const imageDecide =
    <div className="image-container">
      <img src={decideImage} alt="Decide" className="carousel-image" />
    </div>;

  const imageAlert =
    <div className="image-container">
      <img src={alertImage} alt="Alert" className="carousel-image" />
    </div>;

  const imageRespond =
    <div className="image-container">
      <img src={respondImage} alt="Respond Top" className="carousel-image" />
    </div>

  const images = [[imageDecide, "Decide"], [imageAlert, "Alert"], [imageRespond, "Respond"]];

  const timeoutRef = useRef<any | null>(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentImage((prevIndex) => {
          return (prevIndex === images.length - 1 ? 0 : prevIndex + 1);
        }
        ),
      3000
    );

    return () => {
      resetTimeout();
    };
  }, [currentImage]);

  const handleButtonClick = (index: number) => {
    setCurrentImage(index);
  };

  const advanceCarousel = () => {
    setCurrentImage((currentImage === images.length - 1 ? 0 : currentImage + 1));
  };

  return (
    <div>
      <div className="content-container">
        <div className="text-container">
          <div className="section-title">
            <h1>When every second counts...</h1>
          </div>
          <div className="common-text-style first-section-color">
            First Responders turn to <span className='highlight'>Alert Frontier</span> for rapid, reliable, state-of-the-art alerting services.
          </div>
          <div className="common-text-style first-section-color">
            Experience the difference with <strong>advanced tools and services</strong>, empowering <strong>unparalleled response</strong>.
          </div>
          <div className="pilot-content">
            <div className="section-title pilot-text">
              <h3>Pilot with Us!</h3>
            </div>
            <div className="pilot-button-container">
              <Link to="/signup" className="pilot-button">Start Free</Link>
            </div>
          </div>
          <div className="carousel-and-image-container">
            <div className="image-display">
              {images.map((image, idx) => (
                <div key={idx} hidden={currentImage !== idx} onClick={() => advanceCarousel()}>
                  {image[0]}
                </div>
              ))}
            </div>
            <div className="carousel-container">
              <div className="carousel-options">
                {images.map((image, idx) => (
                  <div className="carousel-text" onClick={() => handleButtonClick(idx)} tabIndex={0} style={{ cursor: 'pointer' }}>{image[1]}</div>
                ))}
              </div>
              <div className="carousel-indicators">
                {images.map((_, idx) => (
                  <div className='dot-container' onClick={() => handleButtonClick(idx)}>
                    <span className={currentImage === idx ? 'dot active' : 'dot'}></span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
