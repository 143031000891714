import React, { useState, ChangeEvent } from 'react';
import './DataSubjectAccessForm.css';

const DataSubjectAccessForm: React.FC = () => {
  const [formData, setFormData] = useState({
    organization: '',
    name: '',
    email: '',
    requestAgent: '',
    law: '',
    requestAction: '',
    requestActionDetails: '',
    confirmInfoTrue: false,
    confirmDataDeletion: false,
    confirmContact: false,
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = e.target;
    const value =
      target instanceof HTMLInputElement && target.type === 'checkbox'
        ? target.checked
        : target.value;
    setFormData({ ...formData, [target.name]: value });

    console.log(target.name + ":" + value);
  };

  const validateForm = () => {
    const errors: any = {};

    if (!formData.organization) errors.organization = 'Organization is required.';
    if (!formData.name) errors.name = 'Name is required.';
    if (!formData.email) errors.email = 'Email is required.';
    if (!formData.requestAgent) errors.requestAgent = 'Please select an option.';
    if (!formData.law) errors.law = 'Please select a law.';
    if (!formData.requestAction) errors.requestAction = 'Please select a request type.';

    const allCheckboxesChecked = formData.confirmInfoTrue && formData.confirmDataDeletion && formData.confirmContact;
    if (!allCheckboxesChecked) errors.checkboxes = 'All checkboxes must be checked.';

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (!validateForm()) {
      // If form validation fails, do not proceed with submission
      return;
    }

    try {
      const response = await fetch('https://api.alertfrontier.com/v1/comms/data/access-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setSuccessMessage('Your request has been submitted successfully. We will get back to you shortly.');
      setFormData({
        organization: '',
        name: '',
        email: '',
        requestAgent: '',
        law: '',
        requestAction: '',
        requestActionDetails: '',
        confirmInfoTrue: false,
        confirmDataDeletion: false,
        confirmContact: false,
      });
    } catch (error) {
      setErrorMessage('Failed to submit the request. Please try again later.');
      console.error(error);
    }
  };

  // Check if the form is valid without modifying state
  const isFormValid = formData.organization && formData.name && formData.email && formData.requestAgent && formData.law && formData.requestAction && formData.confirmInfoTrue && formData.confirmDataDeletion && formData.confirmContact;

  return (
    <div className="data-access-form-container">
      <h1>Data Subject Access Request Form</h1>
      <p>Please fill in the information below. The website administrator or data protection officer will be notified of your request within 24 hours and will require an appropriate amount of time to respond.</p>
      
      <form onSubmit={handleSubmit} className="data-access-form">
        <input type="text" name="organization" value={formData.organization} onChange={handleChange} placeholder="Organization (*)" />
        
        <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name (*)" />
        
        <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email Address (*)" />
        
        <label>I am (*):</label>

        <div className="radio-group">
          <div className="radio-item">
            <input 
              type="radio" 
              id="self" 
              name="requestAgent" 
              value="self" 
              checked={formData.requestAgent === "self"} 
              onChange={handleChange} 
            />
            <label htmlFor="self">The person, or the parent/guardian of the person, whose name appears above.</label>
          </div>
          <div className="radio-item">
            <input 
              type="radio" 
              id="agent" 
              name="requestAgent" 
              value="agent" 
              checked={formData.requestAgent === "agent"} 
              onChange={handleChange} 
            />
            <label htmlFor="agent">An agent authorized by the consumer to make this request on their behalf.</label>
          </div>
        </div>
        
        <label>Under the rights of which law are you making this request?</label>
        <select name="law" value={formData.law} onChange={handleChange}>
          <option value="">Select a Law (*)</option>
          <option value="GDPR">GDPR</option>
          <option value="CCPA">CCPA</option>
          <option value="CPA">CPA</option>
          <option value="CTDPA">CTDPA</option>
          <option value="UCPA">UCPA</option>
          <option value="VCDPA">VCDPA</option>
          <option value="OTHER">OTHER</option>
        </select>
        
        <label>I am requesting to (*):</label>
        <div className="radio-group">
          <div className="radio-item">
            <input 
              type="radio" 
              id="knowInfo" 
              name="requestAction" 
              value="knowInfo" 
              checked={formData.requestAction === "knowInfo"} 
              onChange={handleChange} 
            />
            <label htmlFor="knowInfo">Know what information is being collected from me</label>
          </div>
          <div className="radio-item">
            <input 
              type="radio" 
              id="deleteInfo" 
              name="requestAction" 
              value="deleteInfo" 
              checked={formData.requestAction === "deleteInfo"} 
              onChange={handleChange} 
            />
            <label htmlFor="deleteInfo">Have my information deleted</label>
          </div>
          <div className="radio-item">
            <input 
              type="radio" 
              id="optOutSale" 
              name="requestAction" 
              value="optOutSale" 
              checked={formData.requestAction === "optOutSale"} 
              onChange={handleChange} 
            />
            <label htmlFor="optOutSale">Opt out of having my data sold to third parties</label>
          </div>
          <div className="radio-item">
            <input 
              type="radio" 
              id="optInSale" 
              name="requestAction" 
              value="optInSale" 
              checked={formData.requestAction === "optInSale"} 
              onChange={handleChange} 
            />
            <label htmlFor="optInSale">Opt in to the sale of my personal data</label>
          </div>
          <div className="radio-item">
            <input 
              type="radio" 
              id="accessInfo" 
              name="requestAction" 
              value="accessInfo" 
              checked={formData.requestAction === "accessInfo"} 
              onChange={handleChange} 
            />
            <label htmlFor="accessInfo">Access my personal information</label>
          </div>
          <div className="radio-item">
            <input 
              type="radio" 
              id="fixInfo" 
              name="requestAction" 
              value="fixInfo" 
              checked={formData.requestAction === "fixInfo"} 
              onChange={handleChange} 
            />
            <label htmlFor="fixInfo">Fix inaccurate information</label>
          </div>
          <div className="radio-item">
            <input 
              type="radio" 
              id="receiveCopy" 
              name="requestAction" 
              value="receiveCopy" 
              checked={formData.requestAction === "receiveCopy"} 
              onChange={handleChange} 
            />
            <label htmlFor="receiveCopy">Receive a copy of my personal information</label>
          </div>
          <div className="radio-item">
            <input 
              type="radio" 
              id="optOutBehavioral" 
              name="requestAction" 
              value="optOutBehavioral" 
              checked={formData.requestAction === "optOutBehavioral"} 
              onChange={handleChange} 
            />
            <label htmlFor="optOutBehavioral">Opt out of having my data shared for cross-context behavioral advertising</label>
          </div>
          <div className="radio-item">
            <input 
              type="radio" 
              id="limitSensitive" 
              name="requestAction" 
              value="limitSensitive" 
              checked={formData.requestAction === "limitSensitive"} 
              onChange={handleChange} 
            />
            <label htmlFor="limitSensitive">Limit the use and disclosure of my sensitive personal information</label>
          </div>
          <div className="radio-item">
            <input 
              type="radio" 
              id="other" 
              name="requestAction" 
              value="other" 
              checked={formData.requestAction === "other"} 
              onChange={handleChange} 
            />
            <label htmlFor="other">Other (please specify in the comment box below)</label>
          </div>
        </div>
        
        <label>Please leave requestActionDetails regarding your action request or question:</label>
        <textarea name="requestActionDetails" value={formData.requestActionDetails} onChange={handleChange}></textarea>
        
        <label>I confirm that (*):</label>
        <div className="checkbox-group">
          <div className="checkbox-item">
            <input type="checkbox" id="confirmInfoTrue" name="confirmInfoTrue" checked={formData.confirmInfoTrue} onChange={handleChange} />
            <label htmlFor="confirmInfoTrue">Under penalty of perjury, I declare all the above information to be true and accurate.</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="confirmDataDeletion" name="confirmDataDeletion" checked={formData.confirmDataDeletion} onChange={handleChange} />
            <label htmlFor="confirmDataDeletion">I understand that the deletion or restriction of my personal data is irreversible and may result in the termination of services with My Great New Website / App.</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="confirmContact" name="confirmContact" checked={formData.confirmContact} onChange={handleChange} />
            <label htmlFor="confirmContact">I understand that I will be required to validate my request by email, and I may be contacted in order to complete the request.</label>
          </div>
        </div>
        
        <button
          type="submit"
          className="submit-button"
          disabled={!isFormValid}
        >
          Submit Request
        </button>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
}

export default DataSubjectAccessForm;
