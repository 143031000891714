import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import './App.css';
import SignupPage from './pages/SingupPage';
import HomePage from './pages/HomePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DataSubjectAccessForm from './pages/DataSubjectAccessForm';
import Footer from './components/Footer';

const App: React.FC = () => {

  return (
      <div className="App">
        <Header />
        <Routes>        
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/data-subject-access-request" element={<DataSubjectAccessForm />} />
        </Routes> 
        <Footer/>
      </div>
  );
}

export default App;
