import React from 'react';
import './Footer.css';
import { Link, useLocation } from 'react-router-dom'; 

const Footer: React.FC = () => {
    const location = useLocation();
    const hideFooter = location.pathname === '/privacy-policy' || location.pathname === '/data-subject-access-request';
    
    return (
        <footer>
            {!hideFooter && (
                <Link to="/privacy-policy">Privacy Policy</Link>
            )}
        </footer>
    );
};

export default Footer;
