import React, { useState, ChangeEvent } from 'react';
import "./SignupPage.css";

const SignupPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    agency: '',
    cadVendor: '',
    comments: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    setFormData({ ...formData, [target.name]: target.value });
  };

  const handleSubmit = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await fetch('https://api.alertfrontier.com/v1/comms/customers/inquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Handle success - show success message
      setSuccessMessage('Your inquiry has been submitted successfully! We will get back to you shortly.');
      setFormData({ name: '', email: '', agency: '', cadVendor: '', comments: '' });
    } catch (error) {
      setErrorMessage('Failed to submit. Please try again later.');
      console.error(error);
    }
  };

  return (
    <div className="signup-form-container">
      <div className="signup-text-container">
        <h1>Pilot with us!</h1>
        <p>We are actively building out our features and functionality, and are looking to partner with Fire Departments to get valuable feedback and ensure we prioritize critical features.</p>        
        <p>A typical pilot program would involve the following:</p>
        <ul>
          <li><p>Setup a CAD Interface (Email or REST Interface)</p></li>
          <li><p>Provision resources (Units, Stations, Event Codes, etc..)</p></li>
          <li><p>Provide continuous feedback and test out new features as they are rolled out</p></li>
        </ul>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your Name"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your Email"
        />
        <input
          type="text"
          name="agency"
          value={formData.agency}
          onChange={handleChange}
          placeholder="Your Agency"
        />
        <input
          type="text"
          name="cadVendor"
          value={formData.cadVendor}
          onChange={handleChange}
          placeholder="CAD Vendor"
        />
        <textarea
          name="comments"
          value={formData.comments}
          onChange={handleChange}
          placeholder="Any additional comments"
        />
        <button type="button" onClick={handleSubmit} className="email-button">
          Submit
        </button>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
};

export default SignupPage;
