import React from 'react';
import './Header.css';
import logo from '../img/logo.png';
import { Link } from 'react-router-dom'; 

const Header: React.FC = () => {
    return (
        <header>
            <div className="header-container">
                <div className="logo">
                    <Link to="/"> {/* Use Link component to wrap the logo */}
                        <img src={logo} alt="Logo" /> {/* Added alt attribute for accessibility */}
                    </Link>
                </div>
                <Link to="/signup" className="start-for-free-button">Start for free</Link> {/* Converted button to Link */}
            </div>
        </header>
    );
};

export default Header;
